<template>
    <div class="joinForPhone">
        <img src="../assets/images/Join/u2567_div.png" width="100%"/>
        <div class="recruit-dv">
            <div class="recruit-dv-item">
                <img src="../assets/images/Join/u2586.png"/>
                <div class="txt-dv">
                    <div class="txt1">{{ $t('lang.CampusRecruitment') }}</div>
                    <div class="txt2">{{ $t('lang.CurrentStudentsRecentGraduates') }}</div>
                    <div class="txt3" @click="goCampus">{{ $t('lang.LearnMore') }}</div>
                </div>
            </div>
            <div class="recruit-dv-item">
                <div class="txt-dv">
                    <div class="txt1">{{ $t('lang.SocialRecruitment') }}</div>
                    <div class="txt2">{{ $t('lang.IndustryCrossIndustryPersonage') }}</div>
                    <div class="txt3" @click="goSociology">{{ $t('lang.LearnMore') }}</div>
                </div>
                <img src="../assets/images/Join/u2593.png"/>
            </div>
        </div>
        <!-- 科创人 -->
        <div class="IOT-view1-content mt5">
            <div class="IOT-view1-content-text1">{{ $t('lang.advantageInfo.OurAdvantage') }}</div>
            <div style="width: 80px; border-bottom: 2px solid #95f204"></div>
            <div class="title-2 flex justify-content-center align-items-center" style="font-size: 14px; padding: 0 20px; text-align: center">
                {{ $t('lang.FiveAdvantages') }}
            </div>
            <div class="join-view2-content-text3 mt20">
                <img src="../assets/images/Join/p254.png" alt="">
                <img src="../assets/images/Join/p255.png" alt="">
                <img src="../assets/images/Join/p256.png" alt="">
                <img src="../assets/images/Join/p257.png" alt="">
                <img src="../assets/images/Join/p258.png" alt="">
                <img src="../assets/images/Join/p259.png" alt="">
                <img src="../assets/images/Join/p260.png" alt="">
                <img src="../assets/images/Join/p261.png" alt="">
                <img src="../assets/images/Join/p262.png" alt="">
                <img src="../assets/images/Join/p263.png" alt="">
                <img src="../assets/images/Join/p264.png" alt="">
                <img src="../assets/images/Join/p265.png" alt="">
                <img src="../assets/images/Join/p266.png" alt="">
                <img src="../assets/images/Join/p267.png" alt="">
                <img src="../assets/images/Join/p268.png" alt="">
            </div>
        </div>
        <AddressForPhone></AddressForPhone>
    </div>
</template>
<script>
import AddressForPhone from "./addressForPhone";

export default {
    name: "joinForPhone",
    components: {
        AddressForPhone,
    },
    data() {
        return {};
    },

    methods: {
        goCampus() {
            console.log(555)
            this.$router.push({path: "/CampusForPhone"});
        },
        goSociology() {
            this.$router.push({path: "/SociologyForPhone"});
        }
    },
};
</script>
<style lang="scss" scoped>
.recruit-dv {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 10px;
}

.recruit-dv-item {
    position: relative;
    width: 48%;

    img {
        width: 100%;
    }
}

.txt-dv {
    background: rgba($color: #000000, $alpha: 0.4);
    color: #fff;
    position: absolute;
    top: 20%;
    padding: 10px 30px 10px 10px;
}

.txt1 {
    font-size: 1rem;
}

.txt2 {
    font-size: 0.5rem;
    letter-spacing: -0.5px;
}

.txt3 {
    font-size: 0.6rem;
    color: #2997ff;
}

.IOT-view1-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-left: auto;
    margin-right: auto;
}

.IOT-view1-content-text1 {
    font-family: "PingFangSC-Semibold", "PingFang SC Semibold", "PingFang SC",
    sans-serif;
    font-weight: 650;
    font-style: normal;
    font-size: 22px;
    text-align: center;
    padding-bottom: 20px;
    margin-top: 30px;
}

.title-2 {
    font-size: 0.8rem;
    margin-top: 10px;
}

.join-view2-content-text3 {
    width: 100%;
    display: flex;
    align-items: center;
    flex-wrap: wrap;

    img {
        width: 30%;
        margin: 1.6%;
    }
}
</style>
